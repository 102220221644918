import React from "react";
import { Link } from "gatsby";

import imgLB from "../../assets/image/logo-main-black.png";
import imgLW from "../../assets/image/logo-main-white.png";
import imgP1 from "../../assets/image/home-6/png/payment-1.png";
import imgP2 from "../../assets/image/home-6/png/payment-2.png";

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={`bg-default-6 pt-13 pt-lg-23 pb-7 position-relative ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="row pb-lg-18">
            <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
              <div className="pr-xl-20 mb-11 mb-lg-0">
                <div className="brand-logo mb-8">
                  <Link to="/#">
                    <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt=""
                    />
                    <img
                      className="dark-version-logo mx-auto mx-0"
                      src={imgLW}
                      alt=""
                    />
                  </Link>
                </div>
                <p className="font-size-5 mb-0 heading-default-color pr-xs-15 pr-sm-22 pr-md-10 pr-lg-15 pr-xl-10 opacity-7">
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 512 512"><path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"/></svg>
                &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 512 512"><path d="M255.9 120.9l9.1-15.7c5.6-9.8 18.1-13.1 27.9-7.5 9.8 5.6 13.1 18.1 7.5 27.9l-87.5 151.5h63.3c20.5 0 32 24.1 23.1 40.8H113.8c-11.3 0-20.4-9.1-20.4-20.4 0-11.3 9.1-20.4 20.4-20.4h52l66.6-115.4-20.8-36.1c-5.6-9.8-2.3-22.2 7.5-27.9 9.8-5.6 22.2-2.3 27.9 7.5l8.9 15.7zm-78.7 218l-19.6 34c-5.6 9.8-18.1 13.1-27.9 7.5-9.8-5.6-13.1-18.1-7.5-27.9l14.6-25.2c16.4-5.1 29.8-1.2 40.4 11.6zm168.9-61.7h53.1c11.3 0 20.4 9.1 20.4 20.4 0 11.3-9.1 20.4-20.4 20.4h-29.5l19.9 34.5c5.6 9.8 2.3 22.2-7.5 27.9-9.8 5.6-22.2 2.3-27.9-7.5-33.5-58.1-58.7-101.6-75.4-130.6-17.1-29.5-4.9-59.1 7.2-69.1 13.4 23 33.4 57.7 60.1 104zM256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z"/></svg>
                </p>
                <div className="mt-12">
                  <Link
                    to="/#"
                    className="btn btn-pumpkin btn-sm rounded-5 h-60 text-white font-size-5"
                  >
                    <i className="fa fa-lock mr-6" /> Login
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-xl-4 col-lg-3 col-md-4 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-5 opacity-4 mb-10">Product</h4>
                    <ul className="list-unstyled">
                      
                      <li className="mb-7">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          Features
                        </Link>
                      </li>
                      {/* <li className="mb-7">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          Pricing
                        </Link>
                      </li> */}
                      <li className="mb-7">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          About us
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          Book a demo
                        </Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-xl-4 col-lg-3 col-md-4 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-5 opacity-4 mb-10">Resources</h4>
                    <ul className="list-unstyled">
                      <li className="mb-7">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          Pricing
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          Checklist library
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          Blogs
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          Terms &amp; Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-xl-4 col-lg-5 col-md-4 col-xs-8">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-5 opacity-4 mb-10">
                      Follow us on social media
                    </h4>
                    <ul className="footer-social-link d-flex align-items-center justify-content-between list-unstyled flex-wrap mb-10">
                      <li>
                        <Link
                          to="/#"
                          className="text-default-color-2 gr-hover-text-blue-3"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                        </Link>
                      </li>
                      
                      <li>
                        <Link
                          to="/#"
                          className="text-default-color-2 gr-hover-text-blue-3"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
                        </Link>
                      </li>
                      
                      <li>
                        <Link
                          to="/#"
                          className="text-default-color-2 gr-hover-text-blue-3"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30}  viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="border-top border-default-color-4 pt-10">
                    <div className="mb-3">
                      <img className="w-100" src={imgP1} alt="" />
                    </div>
                    <div className="mb-3">
                      <img className="w-100" src={imgP2} alt="" />
                    </div>
                  </div> */}
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
